import React, { Component } from "react";
import logo from "../../assets/images/logo-torino2.png";
import italy from "../../assets/images/flag-italy.png";
import gif from "../../assets/images/stop-torino.gif";
import dm1 from "../../assets/images/dm.png";
import dm2 from "../../assets/images/dm4.png";
import dm3 from "../../assets/images/dm-2.png";
import dm4 from "../../assets/images/dm3.png";
import dm5 from "../../assets/images/dm-11.png";
import footerlogo from "../../assets/images/footer-logo.png";
import england from "../../assets/images/flag-england.png";
import moment from "moment-timezone";
import { HOME } from "../../i18n/home";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import "./Aboutus.style.css";
import { CircularProgress, Typography, Button } from "@mui/material";
import {
  apiPrizeAgent,
  apiPrizeAgentUpdateStatus,
  apiPrizeAgentHistory,
} from "../../services/ConfigServices";
import ClockUpdater from "../../components/ClockerUpdate/index";
import { generateDataRandom } from "../../utils/global";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "white" ? "#ff3535" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow:
    "0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%);",
}));

class Aboutus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateToFormat: new Date(),
      index: 0,
      agentId: "bb90bc50-f6d0-4d56-81f4-d9e312665a27",
      dataBanner: [],
      prizeListData: [],
      historyPrize: [],
      time: {},
      seconds: 0,
      showLoading: false,
      indexing: 0,
      newUpdateData: {},
      arrActiveRecord: [],
      lang: parseInt(props.match.params.id_lang),
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.datetimer = null;
  }

  componentDidMount = () => {
    this.doLoadListPrize();
    this.doLoadHistory();
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });

    this.pollingInterval = setInterval(() => {
      this.doLoadListPrize();
      this.doLoadHistory();
    }, 30 * 1000);

    this.refreshInterval = setInterval(() => {
      this.forceUpdate();
    }, 30 * 1000);
  };

  componentWillUnmount() {
    clearInterval(this.pollingInterval);

    clearInterval(this.refreshInterval);
  }

  doLoadListPrize = () => {
    const formatDateTime = "YYYY-MM-DD HH:mm:ss";
    const formatDate = "YYYY-MM-DD";
    const formatTimeZone = "Europe/Rome";
    const newDate = new Date();
    const currentDateTime = moment(newDate)
      .tz(formatTimeZone)
      .format(formatDateTime);
    const currentDate = moment(newDate).tz(formatTimeZone).format(formatDate);

    // console.log("currentDateTime: ", currentDateTime);
    // console.log("currentDate: ", currentDate);

    const paramRequest = {
      agentId: this.state.agentId,
      dateActive: currentDate,
    };

    apiPrizeAgent(paramRequest)
      .then((res) => {
        const data = res.data;
        if (data.status === "OK" && data.records.length > 0) {
          // console.log("data.records: ", data.records);

          const dataPrizeCurrent = data.records.filter(
            (entry) => entry.date === currentDate
          );

          const dataPrizeAfter = data.records.filter(
            (entry) => entry.date !== currentDate
          );

          // console.log("dataPrizeCurrent: ", dataPrizeCurrent);
          // console.log("dataPrizeAfter: ", dataPrizeAfter);

          const allStatusOne = dataPrizeCurrent.every((entry) =>
            entry.infoPrize.every((prize) => prize.status === 1)
          );

          // console.log(allStatusOne);

          if (allStatusOne) {
            if (dataPrizeAfter.length > 0) {
              const { infoPrize, id_agent, digit } = dataPrizeAfter[0];
              const activeRecord = infoPrize.find(
                (record) => record.status === 0
              );

              // console.log("Prize List Running Records: ", data.records);

              const arrActiveRecord = [];
              arrActiveRecord.push(activeRecord);

              // console.log("arrActiveRecord: ", arrActiveRecord);

              if (activeRecord) {
                // console.log("activeRecord", activeRecord);

                const newRandom = generateDataRandom(1, digit);

                const targetDateTime = moment(
                  `${activeRecord.date_active} ${activeRecord.time_active}`
                );
                const currentRunningDateTime = moment(currentDateTime);

                // console.log("targetDateTime: ", targetDateTime);
                // console.log("currentRunningDateTime: ", currentRunningDateTime);

                let seconds = 0;

                const currentDateObj = new Date(activeRecord.date_active);
                currentDateObj.setDate(currentDateObj.getDate() + 1);
                const updatedDate = currentDateObj.toISOString().split("T")[0];

                const newRequest = {
                  id_agent: id_agent,
                  id_prize: activeRecord.id_prize,
                  item_id: activeRecord.item_id,
                  date_active: activeRecord.date_active,
                  time_active: activeRecord.time_active,
                  sequence: activeRecord.sequence,
                  is_auto: activeRecord.is_auto,
                  newNumber: newRandom[0],
                  newDateActive: updatedDate,
                };

                if (targetDateTime.isAfter(currentRunningDateTime)) {
                  seconds = targetDateTime.diff(
                    currentRunningDateTime,
                    "seconds"
                  );
                  this.startTimer();
                } else {
                  this.doPrizeAgentUpdateStatus(newRequest);
                }

                // console.log(data.records);
                this.setState({
                  seconds,
                  prizeListData: data.records,
                  newUpdateData: newRequest,
                  arrActiveRecord,
                });
              }
            }
          } else {
            const { infoPrize, id_agent, digit } = dataPrizeCurrent[0];
            const activeRecord = infoPrize.find(
              (record) => record.status === 0
            );

            // console.log("Prize List Running Records: ", data.records);

            const arrActiveRecord = [];
            arrActiveRecord.push(activeRecord);

            // console.log("arrActiveRecord: ", arrActiveRecord);

            if (activeRecord) {
              // console.log("activeRecord", activeRecord);

              const newRandom = generateDataRandom(1, digit);

              const targetDateTime = moment(
                `${activeRecord.date_active} ${activeRecord.time_active}`
              );
              const currentRunningDateTime = moment(currentDateTime);

              // console.log("targetDateTime: ", targetDateTime);
              // console.log("currentRunningDateTime: ", currentRunningDateTime);

              let seconds = 0;

              const currentDateObj = new Date(activeRecord.date_active);
              currentDateObj.setDate(currentDateObj.getDate() + 1);
              const updatedDate = currentDateObj.toISOString().split("T")[0];

              const newRequest = {
                id_agent: id_agent,
                id_prize: activeRecord.id_prize,
                item_id: activeRecord.item_id,
                date_active: activeRecord.date_active,
                time_active: activeRecord.time_active,
                sequence: activeRecord.sequence,
                is_auto: activeRecord.is_auto,
                newNumber: newRandom[0],
                newDateActive: updatedDate,
              };

              if (targetDateTime.isAfter(currentRunningDateTime)) {
                seconds = targetDateTime.diff(
                  currentRunningDateTime,
                  "seconds"
                );

                // console.log("seconds: ", seconds);

                this.startTimer();
              } else {
                this.doPrizeAgentUpdateStatus(newRequest);
              }

              // console.log(data.records);
              this.setState({
                seconds,
                prizeListData: data.records,
                newUpdateData: newRequest,
                arrActiveRecord,
              });
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doPrizeAgentUpdateStatus = (params) => {
    apiPrizeAgentUpdateStatus(params)
      .then((res) => {
        // console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          this.doLoadListPrize();
          this.doLoadHistory();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doLoadHistory = () => {
    // const formatDateTime = "YYYY-MM-DD HH:mm:ss";
    const formatDate = "YYYY-MM-DD";
    const formatTimeZone = "Europe/Rome";
    const newDate = new Date();
    // const currentDateTime = moment(newDate)
    //   .tz(formatTimeZone)
    //   .format(formatDateTime);
    const currentDate = moment(newDate).tz(formatTimeZone).format(formatDate);

    // console.log("currentDateTime: ", currentDateTime);

    let params = { agentId: this.state.agentId };
    apiPrizeAgentHistory(params)
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            // console.log("History Records: ", data.records);
            // let history = data.records.sort(function (a, b) {
            //   return new Date(a.date_active) - new Date(b.date_active);
            // });

            const dataHistory = data.records.filter(
              (entry) => currentDate >= entry.date
            );

            this.setState({
              historyPrize: dataHistory,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  langButtonIt = () => {
    this.setState({ lang: 0 });
  };

  langButtonEn = () => {
    this.setState({ lang: 1 });
  };

  handleChangeIndex = (index) => {
    this.setState({
      index,
    });
  };

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours.toString().padStart(2, "0"),
      m: minutes.toString().padStart(2, "0"),
      s: seconds.toString().padStart(2, "0"),
    };
    return obj;
  }

  startTimer() {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  homeButton = () => {
    this.props.history.push("/home/" + this.state.lang);
  };

  resultButton = () => {
    this.props.history.push("/result/" + this.state.lang);
  };

  howButton = () => {
    this.props.history.push("/how-to-play/" + this.state.lang);
  };
  playButton = () => {
    this.props.history.push("/play-responsibility/" + this.state.lang);
  };
  aboutButton = () => {
    this.props.history.push("/about-us/" + this.state.lang);
  };

  countDown() {
    const formatDate = "YYYY-MM-DD";
    const formatTimeZone = "Europe/Rome";
    const newDate = new Date();
    const currentDate = moment(newDate).tz(formatTimeZone).format(formatDate);

    let seconds = this.state.seconds - 1;

    // console.log("seconds:", seconds);

    if (
      this.state.arrActiveRecord[0].sequence === 7 &&
      this.state.arrActiveRecord[0].date_active === currentDate
    ) {
      if (seconds >= 0) {
        this.setState({
          time: this.secondsToTime(seconds),
          seconds: seconds,
        });

        if (seconds <= 60 && seconds >= 1) {
          // console.log("60 second sampai 1");
          this.setState({
            showLoading: true,
          });
        } else if (seconds <= 1) {
          // console.log("0");
          clearInterval(this.timer);
          this.timer = 0;
          this.setState({
            showLoading: true,
          });

          let obj = {
            h: "--",
            m: "--",
            s: "--",
          };

          this.setState({
            time: obj,
          });

          const delay = 10000;
          new Promise((resolve) => setTimeout(resolve, delay)).then(() =>
            this.doSendUpdateHistory()
          );
        }
      } else {
        clearInterval(this.timer);
        // console.log("Countdown complete!");
      }
    } else if (
      this.state.arrActiveRecord[0].sequence !== 7 &&
      this.state.arrActiveRecord[0].date_active === currentDate
    ) {
      let obj = {
        h: "--",
        m: "--",
        s: "--",
      };

      if (seconds >= 0) {
        this.setState({
          seconds: seconds,
          time: obj,
        });

        if (seconds <= 60 && seconds >= 1) {
          // console.log("60 second sampai 1");
          this.setState({
            showLoading: true,
          });
        } else if (seconds <= 1) {
          // console.log("0");
          clearInterval(this.timer);
          this.timer = 0;
          this.setState({
            showLoading: true,
          });

          const delay = 1000;
          new Promise((resolve) => setTimeout(resolve, delay)).then(() =>
            this.doSendUpdateHistory()
          );
        }
      } else {
        clearInterval(this.timer);
        // console.log("Countdown complete!");
      }
    } else {
      if (seconds >= 0) {
        this.setState({
          time: this.secondsToTime(seconds),
          seconds: seconds,
        });

        if (seconds <= 60 && seconds >= 1) {
          // console.log("60 second sampai 1");
          this.setState({
            showLoading: true,
          });
        } else if (seconds <= 1) {
          // console.log("0");
          clearInterval(this.timer);
          this.timer = 0;
          this.setState({
            showLoading: true,
          });

          const delay = 1000;
          new Promise((resolve) => setTimeout(resolve, delay)).then(() =>
            this.doSendUpdateHistory()
          );
        }
      } else {
        clearInterval(this.timer);
        // console.log("Countdown complete!");
      }
    }
  }

  doSendUpdateHistory = () => {
    // this.setState({ showLoading: false });
    this.doPrizeAgentUpdateStatus(this.state.newUpdateData);
  };

  processHistoryPrize = (historyPrize, seqA, seqB) => {
    const filteredData = historyPrize.map((item) => {
      const filteredInfoPrize = item.infoPrize.filter(
        (prize) => prize.sequence >= seqA && prize.sequence <= seqB
      );

      return {
        ...item,
        infoPrize: filteredInfoPrize,
      };
    });

    const filteredStatusData = filteredData.map((item) => {
      const filteredInfoPrize = item.infoPrize.filter(
        (prize) => prize.status === 1
      );

      return {
        ...item,
        infoPrize: filteredInfoPrize,
      };
    });

    const concatenatedInfoPrize = filteredStatusData.reduce((result, item) => {
      return result.concat(item.infoPrize);
    }, []);

    const filteredInfoPrize = concatenatedInfoPrize.filter(
      (prize) => prize.status === 1
    );

    const sortedInfoPrize = filteredInfoPrize.sort(
      (a, b) => a.sequence - b.sequence
    );

    const sortedAndFilteredInfoPrize = sortedInfoPrize
      .sort((a, b) => {
        const dateA = new Date(a.date_active + " " + a.time_active);
        const dateB = new Date(b.date_active + " " + b.time_active);
        return dateA - dateB;
      })
      .reduce((result, prize) => {
        const existingItem = result.find(
          (item) => item.sequence === prize.sequence
        );

        if (!existingItem) {
          result.push(prize);
        } else {
          const existingDate = new Date(
            existingItem.date_active + " " + existingItem.time_active
          );
          const currentDate = new Date(
            prize.date_active + " " + prize.time_active
          );

          if (currentDate > existingDate) {
            result[result.indexOf(existingItem)] = prize;
          }
        }

        return result;
      }, []);

    const sortedArray = sortedAndFilteredInfoPrize.sort(
      (a, b) => a.sequence - b.sequence
    );

    return sortedArray;
  };

  renderPrizeWinner = () => {
    const formatDate = "YYYY-MM-DD";
    const formatTimeZone = "Europe/Rome";
    const newDate = new Date();

    var currentDate = moment(newDate).tz(formatTimeZone).format(formatDate);

    const resultHistory123Before = this.processHistoryPrize(
      this.state.historyPrize,
      1,
      3
    );

    const filteredDataHistoryCurrent = this.state.historyPrize.filter(
      (entry) => entry.date === currentDate
    );

    const hasStatusOne = filteredDataHistoryCurrent.some((entry) =>
      entry.infoPrize.some((prize) => prize.status === 1)
    );

    if (hasStatusOne) {
      const updatedData = filteredDataHistoryCurrent.map((entry) => {
        const updatedInfoPrize = entry.infoPrize.map((prize) => ({
          ...prize,
          isLoading: true,
        }));

        return {
          ...entry,
          infoPrize: updatedInfoPrize,
        };
      });

      const infoPrizeArray = updatedData.map((entry) => entry.infoPrize);

      const getHistoryCurrentSequence123 = infoPrizeArray[0].filter(
        (item) =>
          item.sequence === 1 || item.sequence === 2 || item.sequence === 3
      );

      if (getHistoryCurrentSequence123.length > 0) {
        return (
          <div className="crrnt-prize">
            {getHistoryCurrentSequence123.map((obj, i) => (
              <div key={i} className="time-box-2">
                <div className="win-1">
                  <div>
                    <span className="adjust-font">
                      {i === 1 ? "2nd" : i === 2 ? "3rd" : "1st"} Prize
                    </span>
                  </div>
                  {[...obj.nomor].map((item, j) => (
                    <div key={j} className="win-2">
                      {j === obj.nomor.length - 1 ? (
                        <div className="item-prizeRed">
                          {obj.isLoading === true && obj.status === 0 ? (
                            <CircularProgress
                              size={15}
                              style={{ color: "#fff" }}
                            />
                          ) : (
                            item
                          )}
                        </div>
                      ) : (
                        <div className="item-prize">
                          {obj.isLoading === true && obj.status === 0 ? (
                            <CircularProgress
                              size={15}
                              style={{ color: "#fff" }}
                            />
                          ) : (
                            item
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        );
      }
    } else {
      if (resultHistory123Before.length > 0) {
        return (
          <div className="crrnt-prize">
            {resultHistory123Before.map((obj, i) => (
              <div key={i} className="time-box-2">
                <div className="win-1">
                  <div>
                    <span className="adjust-font">
                      {i === 1 ? "2nd" : i === 2 ? "3rd" : "1st"} Prize
                    </span>
                  </div>
                  {[...obj.nomor].map((item, j) => (
                    <div key={j} className="win-2">
                      {j === obj.nomor.length - 1 ? (
                        <div className="item-prizeRed">
                          {" "}
                          {this.state.showLoading ? (
                            <CircularProgress
                              size={15}
                              style={{ color: "#fff" }}
                            />
                          ) : (
                            item
                          )}
                        </div>
                      ) : (
                        <div className="item-prize">
                          {" "}
                          {this.state.showLoading ? (
                            <CircularProgress
                              size={15}
                              style={{ color: "#fff" }}
                            />
                          ) : (
                            item
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        );
      } else {
        return (
          <div className="crrnt-prize">
            <Item item xs={4} className="time-box-2">
              <div className="win-1">
                <div>
                  <span className="adjust-font">1st Prize</span>
                </div>
                <div className="win-2">
                  <div className="item-prize"> X </div>
                  <div className="item-prize"> X </div>
                  <div className="item-prize"> X </div>
                  <div className="item-prize"> X </div>
                  <div className="item-prize"> X </div>
                  <div className="item-prizeRed">X</div>
                </div>
              </div>
            </Item>

            <Item item xs={4} className="time-box-2">
              <div className="win-1">
                <div>
                  <span className="adjust-font">2st Prize</span>
                </div>
                <div className="win-2">
                  <div className="item-prize"> X </div>
                  <div className="item-prize"> X </div>
                  <div className="item-prize"> X </div>
                  <div className="item-prize"> X </div>
                  <div className="item-prize"> X </div>
                  <div className="item-prizeRed">X</div>
                </div>
              </div>
            </Item>

            <Item item xs={4} className="time-box-2">
              <div className="win-1">
                <div>
                  <span className="adjust-font">3st Prize</span>
                </div>
                <div className="win-2">
                  <div className="item-prize"> X </div>
                  <div className="item-prize"> X </div>
                  <div className="item-prize"> X </div>
                  <div className="item-prize"> X </div>
                  <div className="item-prize"> X </div>
                  <div className="item-prizeRed">X</div>
                </div>
              </div>
            </Item>
          </div>
        );
      }
    }
  };

  renderInitial4and5 = () => {
    const formatDate = "YYYY-MM-DD";
    const formatTimeZone = "Europe/Rome";
    var newDate = new Date();

    var currentDate = moment(newDate).tz(formatTimeZone).format(formatDate);

    const resultHistory45Before = this.processHistoryPrize(
      this.state.historyPrize,
      4,
      5
    );

    const filteredDataHistoryCurrent = this.state.historyPrize.filter(
      (entry) => entry.date === currentDate
    );

    const hasStatusOne = filteredDataHistoryCurrent.some((entry) =>
      entry.infoPrize.some((prize) => prize.status === 1)
    );

    if (hasStatusOne) {
      const updatedData = filteredDataHistoryCurrent.map((entry) => {
        const updatedInfoPrize = entry.infoPrize.map((prize) => ({
          ...prize,
          isLoading: true,
        }));

        return {
          ...entry,
          infoPrize: updatedInfoPrize,
        };
      });

      const infoPrizeArray = updatedData.map((entry) => entry.infoPrize);

      const getHistoryCurrentSequence4and5 = infoPrizeArray[0].filter(
        (item) => item.sequence === 4 || item.sequence === 5
      );

      if (getHistoryCurrentSequence4and5.length > 0) {
        return (
          <div className="number-start">
            {getHistoryCurrentSequence4and5.map((item, index) => (
              <div key={index} className={`start-prize${index + 1}`}>
                {item.isLoading === true && item.status === 0
                  ? Array.from({ length: item.nomor.length }).map((_, i) => (
                      <CircularProgress
                        key={i}
                        size={20}
                        style={{ color: "#fff", marginRight: 5 }}
                      />
                    ))
                  : item.nomor}
              </div>
            ))}
          </div>
        );
      }
    } else {
      if (resultHistory45Before.length > 0) {
        return (
          <div className="number-start">
            {resultHistory45Before.map((item, index) => (
              <div key={index} className={`start-prize${index + 1}`}>
                {this.state.showLoading
                  ? Array.from({ length: item.nomor.length }).map((_, i) => (
                      <CircularProgress
                        key={i}
                        size={20}
                        style={{ color: "#fff", marginRight: 5 }}
                      />
                    ))
                  : item.nomor}
              </div>
            ))}
          </div>
        );
      } else {
        return (
          <div className="number-start">
            <div className="start-prize1">XXXXXX</div>
            <div className="start-prize2">XXXXXX</div>
          </div>
        );
      }
    }
  };

  renderConsolation6and7 = () => {
    const formatDate = "YYYY-MM-DD";
    const formatTimeZone = "Europe/Rome";
    var newDate = new Date();

    var currentDate = moment(newDate).tz(formatTimeZone).format(formatDate);
    const resultHistory67Before = this.processHistoryPrize(
      this.state.historyPrize,
      6,
      7
    );

    const filteredDataHistoryCurrent = this.state.historyPrize.filter(
      (entry) => entry.date === currentDate
    );

    const hasStatusOne = filteredDataHistoryCurrent.some((entry) =>
      entry.infoPrize.some((prize) => prize.status === 1)
    );

    if (hasStatusOne) {
      const updatedData = filteredDataHistoryCurrent.map((entry) => {
        const updatedInfoPrize = entry.infoPrize.map((prize) => ({
          ...prize,
          isLoading: true,
        }));

        return {
          ...entry,
          infoPrize: updatedInfoPrize,
        };
      });

      const infoPrizeArray = updatedData.map((entry) => entry.infoPrize);

      const getHistoryCurrentSequence6and7 = infoPrizeArray[0].filter(
        (item) => item.sequence === 6 || item.sequence === 7
      );

      if (getHistoryCurrentSequence6and7.length > 0) {
        return (
          <div className="number-start">
            {getHistoryCurrentSequence6and7.map((item, index) => (
              <div key={index} className={`start-prize${index + 1}`}>
                {item.isLoading === true && item.status === 0
                  ? Array.from({ length: item.nomor.length }).map((_, i) => (
                      <CircularProgress
                        key={i}
                        size={20}
                        style={{ color: "#fff", marginRight: 5 }}
                      />
                    ))
                  : item.nomor}
              </div>
            ))}
          </div>
        );
      }
    } else {
      if (resultHistory67Before.length > 0) {
        return (
          <div className="number-start">
            {resultHistory67Before.map((item, index) => (
              <div key={index} className={`start-prize${index + 1}`}>
                {this.state.showLoading
                  ? Array.from({ length: item.nomor.length }).map((_, i) => (
                      <CircularProgress
                        key={i}
                        size={20}
                        style={{ color: "#fff", marginRight: 5 }}
                      />
                    ))
                  : item.nomor}
              </div>
            ))}
          </div>
        );
      } else {
        return (
          <div className="number-start">
            <div className="start-prize1">XXXXXX</div>
            <div className="start-prize2">XXXXXX</div>
          </div>
        );
      }
    }
  };

  renderCountDown = () => {
    return (
      <div className="countdown-time">
        <span className="name-cd">
          {" "}
          {this.state.lang === 0 ? HOME.it.Time : HOME.en.Time}
        </span>
        <div className="cd-time">
          <Item item xs={4} className="time-box">
            {this.state.time.h}
          </Item>
          <Item item xs={4} className="time-box">
            {this.state.time.m}
          </Item>
          <Item item xs={4} className="time-box">
            {this.state.time.s}
          </Item>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div maxWidth="xs" xs={12} style={{ backgroundColor: "#ffff" }}>
        <div className="header-logo global-center">
          <img src={logo} alt="logo" />
          <div className="second-header">
            <div className="moment-home">
              <ClockUpdater />
            </div>
            <div style={{ display: "flex" }}>
              <Button onClick={() => this.langButtonIt()}>
                <img
                  src={italy}
                  className="flag-img"
                  alt="italy"
                  style={{ marginRight: "3px" }}
                />
              </Button>
              <Button onClick={() => this.langButtonEn()}>
                <img src={england} className="flag-img" alt="england" />
              </Button>
            </div>
          </div>
        </div>
        <div className="nav-bar global-center">
          <div className="global-center">
            <ul>
              <li>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#e61818",
                    boxShadow:
                      "0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%)",
                  }}
                  onClick={() => this.homeButton()}
                >
                  <Typography
                    variant="button"
                    style={{
                      fontSize: 12,
                      color: "#fff",
                      fontWeight: 100,
                    }}
                  >
                    {this.state.lang === 0 ? HOME.it.Home : HOME.en.Home}
                  </Typography>
                </Button>
              </li>
              <li>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#e61818",
                    boxShadow:
                      "0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%)",
                  }}
                  onClick={() => this.resultButton()}
                >
                  <Typography
                    variant="button"
                    style={{
                      fontSize: 12,
                      color: "#fff",
                      fontWeight: 100,
                    }}
                  >
                    {this.state.lang === 0 ? HOME.it.Result : HOME.en.Result}
                  </Typography>
                </Button>
              </li>
              <li>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#e61818",
                    boxShadow:
                      "0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%)",
                  }}
                  onClick={() => this.howButton()}
                >
                  <Typography
                    variant="button"
                    style={{
                      fontSize: 12,
                      color: "#fff",
                      fontWeight: 100,
                    }}
                  >
                    {this.state.lang === 0
                      ? HOME.it.How_to_play
                      : HOME.en.How_to_play}
                  </Typography>
                </Button>
              </li>
              <li>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#e61818",
                    boxShadow:
                      "0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%)",
                  }}
                  onClick={() => this.playButton()}
                >
                  <Typography
                    variant="button"
                    style={{
                      fontSize: 12,
                      color: "#fff",
                      fontWeight: 100,
                    }}
                  >
                    {this.state.lang === 0
                      ? HOME.it.Play_responsibility
                      : HOME.en.Play_responsibility}
                  </Typography>
                </Button>
              </li>
              <li>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#e61818",
                    boxShadow:
                      "0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%)",
                  }}
                  onClick={() => this.aboutButton()}
                >
                  <Typography
                    variant="button"
                    style={{
                      fontSize: 12,
                      color: "#fff",
                      fontWeight: 100,
                    }}
                  >
                    {this.state.lang === 0
                      ? HOME.it.About_us
                      : HOME.en.About_us}
                  </Typography>
                </Button>
              </li>
            </ul>
          </div>
        </div>

        <div className="global-center adjust-result">
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={4}>
              <Item className="box-trans">
                <div className="next-draw box-trans">
                  {" "}
                  {this.state.lang === 0
                    ? HOME.it.Next_drawing
                    : HOME.en.Next_drawing}
                </div>
                {this.renderCountDown()}
                <div className="next-draw-2">
                  {" "}
                  {this.state.lang === 0
                    ? HOME.it.Winning_Number
                    : HOME.en.Winning_Number}
                </div>
                <div className="countdown-time">
                  <div className="current-pr">
                    <span className="name-cd">CURRENT PRIZE</span>
                    <div>
                      <ClockUpdater />
                    </div>
                  </div>
                  {this.renderPrizeWinner()}
                  <div className="adjust-start">
                    <div className="start-prize">
                      {" "}
                      {this.state.lang === 0
                        ? HOME.it.starter_prize
                        : HOME.en.starter_prize}
                    </div>
                    {this.renderInitial4and5()}
                  </div>
                  <div className="adjust-start">
                    <div className="start-prize">
                      {" "}
                      {this.state.lang === 0
                        ? HOME.it.consolation_prize
                        : HOME.en.consolation_prize}
                    </div>
                    {this.renderConsolation6and7()}
                  </div>
                </div>
              </Item>
            </Grid>
            <Grid item xs={12} md={8}>
              <Item>
                <div className="text-how">
                  <p>
                    <span className="red-text">
                      {this.state.lang === 0
                        ? HOME.it.About_us
                        : HOME.en.About_us}
                    </span>
                  </p>
                  <p>
                    <a href="/">Torinopools.com</a> is dedicated to providing
                    results and information about the Torino Pools Lottery. It
                    covers everything you might need to know about the game,
                    whether you are a regular player or completely new to the
                    world of lotteries.
                  </p>
                  <p>
                    The site offers easy access to a complete set of results,
                    covering every Torino Pools Lottery draw since the lottery
                    launched in 2002. The latest winning numbers are displayed
                    straight after every draw on almost everyday.
                  </p>
                  <p>
                    The comprehensive results database allows{" "}
                    <a href="/">Torinopools.com</a> is dedicated to providing to
                    provide in-depth statistics about the numbers that have been
                    drawn. You can also find news about who has won the latest
                    big jackpot or announcements about upcoming special events,
                    such as SuperBull Jackpot, in which incredible prizes of
                    more than €2 million can be won.
                  </p>
                </div>
                <div className="gif-content" style={{ display: "none" }}>
                  <img src={gif} alt="gif" className="adjust-gif"></img>
                </div>
              </Item>
            </Grid>
          </Grid>
        </div>
        <div className="footer-container">
          <div className="global-center adjust-footer">
            <div className="grp-img">
              <img src={dm1} alt="dm1" className="footer-img" />
              <img src={dm2} alt="dm2" className="footer-img dm-2" />
              <img src={dm3} alt="dm3" className="footer-img dm-3" />
              <img src={dm4} alt="dm4" className="footer-img dm-4" />
              <img src={dm5} alt="dm5" className="footer-img" />
            </div>
            <div className="white-line"></div>
            <div className="terms-text">
              <p>{this.state.lang === 0 ? HOME.it.footer1 : HOME.en.footer1}</p>
              <p>{this.state.lang === 0 ? HOME.it.footer2 : HOME.en.footer2}</p>
              <p>{this.state.lang === 0 ? HOME.it.footer3 : HOME.en.footer3}</p>
              <p>{this.state.lang === 0 ? HOME.it.footer4 : HOME.en.footer4}</p>
            </div>
            <div className="white-line"></div>
            <div className="href-footer">
              <a href="/">
                {" "}
                {this.state.lang === 0 ? HOME.it.About_us : HOME.en.About_us}
              </a>
              <a href="/">
                {" "}
                {this.state.lang === 0
                  ? HOME.it.Terms_and_condition
                  : HOME.en.Terms_and_condition}
              </a>
              <a href="/">
                {" "}
                {this.state.lang === 0
                  ? HOME.it.Privacy_policy
                  : HOME.en.Privacy_policy}
              </a>
            </div>
            <div className="grp-img-2">
              <img src={footerlogo} alt="dm1" className="footer-img" />
            </div>
            <div className="terms-text copyright-text">
              <p>Copyright © torinopools.com</p>
            </div>
          </div>
        </div>
        {this.startTimer()}
      </div>
    );
  }
}
export default Aboutus;
