import axios from "axios";
import { webserviceurl } from "./BaseUrl";

export function apiBanner(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "banner.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiNumber(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "prize_list_agent.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiHistory(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "history_prize_list.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiHistoryIndex(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "history_prize_list_index.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiUpdateHistoryBukaanCreateNewBukaan(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "prize_update_agent.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiPrizeAgent(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "prize_agent_list.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiPrizeAgentHistory(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "prize_agent_history.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiPrizeAgentUpdateStatus(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "prize_agent_update_status.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}
