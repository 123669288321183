export function generateData(size, digit) {
  var digits = digit;
  return Array.apply(null, { length: size || 10 }).map(function () {
    return Math.floor(
      Math.random() * Math.pow(10, digits) + Math.pow(10, digits)
    )
      .toString()
      .slice(-digits);
  });
}

export function generateDataRandom(size, digit) {
  var digits = digit;
  var digitArray = Array.from({ length: 10 }, (_, i) => i);

  return Array.apply(null, { length: size || 10 }).map(function () {
    for (var i = digitArray.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      [digitArray[i], digitArray[j]] = [digitArray[j], digitArray[i]];
    }

    var randomValue = digitArray.slice(0, digits);

    if (randomValue[0] === 0) {
      randomValue[0] = Math.floor(Math.random() * 9) + 1; // Replace 0 with a random non-zero digit
    }

    var randomNumber = randomValue.join("");
    return parseInt(randomNumber, 10);
  });
}
