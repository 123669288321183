import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import HomePage from "./pages/Home/Home";
import Result from "./pages/Result/Result";
import Howtoplay from "./pages/Howtoplay/Howtoplay";
import Playresponsibility from "./pages/Playresponsibility/Playresponsibility";
import Aboutus from "./pages/Aboutus/Aboutus";

class App extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/home/:id_lang" component={HomePage} />
        <Route path="/result/:id_lang" component={Result} />
        <Route path="/how-to-play/:id_lang" component={Howtoplay} />
        <Route
          path="/play-responsibility/:id_lang"
          component={Playresponsibility}
        />
        <Route path="/about-us/:id_lang" component={Aboutus} />
      </Switch>
    );
  }
}
export default App;
