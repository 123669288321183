import React, { useEffect, useState } from "react";
import moment from "moment-timezone";

const ClockUpdater = () => {
  const [currentTime, setCurrentTime] = useState(
    moment().tz("Europe/Rome").format("L, HH:mm:ss")
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment().tz("Europe/Rome").format("L, HH:mm:ss"));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return <>{currentTime}</>;
};

export default ClockUpdater;
