export const HOME = {
  it: {
    Home: "Casa",
    Result: "Risultato",
    How_to_play: "Come Pagere",
    Play_responsibility: "Responsabile",
    About_us: "Riguardo A Noi",
    Next_drawing: "PROSSIMA ESTRAZIONE",
    Time: "TEMPO",
    Winning_Number: "NUMERI VINCENTI",
    Current_prize: "SORTEGGIO ATTUALE",
    first_prize: "PRIMO",
    second_prize: "SECONDO",
    third_prize: "TERZO",
    starter_prize: "PREMIO INIZIALE",
    consolation_prize: "PREMIO DI CONSOLAZIONE",
    latest_result: "Ultimo Risultato",
    date: "Data",
    footer1:
      "I giocatori devono avere almeno 18 anni. Il gioco d'azzardo può essere dannoso se non controllato. Per favore, gioca in modo responsabile.",
    footer2:
      "Viene fatto ogni sforzo per garantire l'accuratezza dei numeri vincenti, i premi in denaro e altre informazioni pubblicate su Torinopools.com. I numeri vincenti ufficiali sono quelli selezionati nei rispettivi disegni e registrati sotto l'osservazione di una società di revisione indipendente.",
    footer3:
      "In caso di discrepanza, prevarranno i risultati del sorteggio ufficiale.",
    footer4:
      '"Il marchio Torinopools", reg. N. 779961372 è di proprietà e utilizzato con il permesso della Bufalopalle Lottery Association.',
    Terms_and_condition: "Termini e condizioni",
    Privacy_policy: "Politica sulla riservatezza",
    htp1: "La lotteria Torino Pools è stata lanciata nel 2002 ed è diventato uno dei giochi di lotteria più popolari. La lotteria è gestita da Bufalopalle e le estrazioni si svolgono tradizionalmente quasi ogni giorno alle 20:00 ora locale (GMT + 2)",
    htp2: "Per giocare a Torino Pools Lottery, i giocatori selezionano sei numeri tra 0 e 9 e il jackpot viene vinto indovinando tutti e sei i numeri principali. Se nessun giocatore indovina i numeri vincenti del jackpot, il primo premio verrà trasferito all'estrazione successiva. Poiché i giocatori devono indovinare sei numeri da un ampio pool di numeri per vincere il primo premio, i jackpot della Torino Pools Lottery si rinnovano regolarmente e poiché non esiste un limite per il jackpot, i primi premi sostanziali non sono insoliti. Ad oggi, il più grande jackpot della Torino Pools Lottery ammonta a 2,7 milioni di euro ed è stato vinto da un singolo titolare di biglietto nell'aprile 2010. Tuttavia, ci sono molti altri modi per vincere premi, poiché i giocatori possono ottenere grandi premi in denaro abbinando anche solo due numeri principali del gioco.",
    htp3: "Premi SuperBull della lotteria dei pool di Torino",
    htp4: "Oltre ai principali premi della lotteria Torino Pools, i giocatori hanno la possibilità di vincere premi aggiuntivi attraverso il side-game SuperBull. Per giocare a SuperBull, i giocatori selezionano normalmente i loro numeri della lotteria dei pool di Torino e, a un costo aggiuntivo, selezionano un numero di SuperBull compreso tra 1 e 90. Se il giocatore abbina il SuperBull pescato insieme a una delle cinque combinazioni vincenti disponibili nel gioco principale e incontra alcuni o tutti i numeri principali estratti, il premio viene aumentato e in alcuni casi moltiplicato per un massimo di 100. I possessori del biglietto SuperBull sono anche garantito per vincere premi purché il numero SuperBull sul loro biglietto corrisponda a quello estratto. I premi SuperBull della lotteria Torino Pools sono i seguenti:",
    wincom: "Combinato Vincente",
    prz: "Premio",
    prz1: "Primo livello del premio della lotteria Torino Pools più € 10.000",
    prz2: "100x il premio Match 4 della lotteria Torino Pools",
    prz3: "100x il premio Match 3 della lotteria Torino Pools",
    htp5: "* In circostanze eccezionali, i giocatori della lotteria Torino Pools possono ricevere un premio inferiore a quello pubblicizzato, che sarà calcolato in base al montepremi e al numero di vincitori.",
    pr: "GIOCA RESPONSABILMENTE",
    pr1: "Gambling should never be a problem.",
    pr2: "La lotteria Torino Pools vuole che tutti si divertano giocando ai giochi della lotteria e vogliono che tutti giochino in modo responsabile. Crediamo che un giocatore problematico sia uno di troppo. La maggior parte delle persone gioca d'azzardo per divertimento e usa i soldi del proprio budget di intrattenimento per andare in un casinò o una corsa di cavalli, per giocare a carte o bingo o per acquistare un biglietto della lotteria.",
    pr3: "Ma, secondo il Consiglio comunale sul problema del gioco d'azzardo, alcune persone, circa il tre percento degli adulti, hanno problemi legati al gioco d'azzardo. Queste persone non possono gestire in modo sicuro nessun tipo di scommessa. Hanno una malattia chiamata problema d'azzardo. Fortunatamente c'è aiuto per coloro che pensano che loro o qualcuno a cui tengono potrebbero avere problemi con il gioco d'azzardo.",
    pr4: "Numerose organizzazioni private offrono assistenza e anche noi. Poiché siamo un'agenzia la cui missione è fornire benefici pubblici, le persone con problemi di gioco d'azzardo e le loro famiglie possono trovare informazioni, consulenza e assistenza per il gioco d'azzardo problematico presso i rivenditori della lotteria dei pool di Torino, la linea di aiuto per i problemi di gioco d'azzardo al numero: 0808 8020 133. Oppure può visitare il sito Web BeGambleAware per ulteriori informazioni.",
  },
  en: {
    Home: "Home",
    Result: "Result",
    How_to_play: "How To Play",
    Play_responsibility: "Play Responsibility",
    About_us: "About Us",
    Next_drawing: "NEXT DRAWING",
    Time: "TIME",
    Winning_Number: "WINNING NUMBER",
    Current_prize: "CURRENT PRIZE",
    first_prize: "1st Prize",
    second_prize: "2nd Prize",
    third_prize: "3rd Prize",
    starter_prize: "STARTER PRIZE",
    consolation_prize: "CONSOLATION PRIZE",
    latest_result: "Latest Result",
    date: "Date",
    footer1:
      "Players Must Be 18 Years or Older. Gambling can be harmful if not controlled. Please play responsibly.",
    footer2:
      "Every effort is made to ensure the accuracy of the winning numbers, prize payouts and other information posted on Torinopools.com. The official winning numbers are those selected in the respective drawings and recorded under the observation of an independent accounting firm.",
    footer3:
      "In the event of a discrepancy, the official drawing results shall prevail.",
    footer4:
      '"The trademark "Torinopools", reg. nos. 779961372 are owned by and used with the permission of the Bufalopalle Lottery Association.',
    Terms_and_condition: "Term and Condition",
    Privacy_policy: "Privacy Policy",
    htp1: "Torino Pools Lottery launched in 2002 and has become one of the most popular lottery games. The lottery is operated by Bufalopalle and draws traditionally take place almost everyday at 20:00 local time (GMT+2).",
    htp2: "To play Torino Pools Lottery, players select six numbers between 0 and 9 and the jackpot is won by matching all six main numbers. If no player matches the winning jackpot numbers, the top prize will roll over to the following draw. As players are required to match six numbers from a large pool of numbers in order to win the top prize, Torino Pools Lottery jackpots regularly roll over and as there is no jackpot cap, substantial top prizes are not unusual. To date, the largest Torino Pools Lottery jackpot stands at €2.7 million and was won by a single ticket holder in April 2010. There are many others ways to win prizes, though, as players can scoop great cash rewards from matching as few as two main numbers in the game.",
    htp3: "Torino Pools Lottery SuperBull Prizes",
    htp4: "In addition to the main Torino Pools Lottery prizes, players have the chance to win additional prizes through the SuperBull side-game. To play SuperBull, players select their Torino Pools Lottery numbers as normal and, for an additional fee, select a SuperBull number between 1 and 90. If the player matches the SuperBull drawn alongside any of the five winning combinations available in the main game and matches some or all of the main numbers drawn, the prize is increased and in some cases multiplied by as much as 100. SuperBull ticket holders are also guaranteed to win prizes as long as the SuperBull number on their ticket matches the one drawn. The Torino Pools Lottery SuperBull prizes are as follows:",
    wincom: "Winning Combination",
    prz: "Prize",
    prz1: "First tier the Torino Pools Lottery prize plus €10.000",
    prz2: "100x the Torino Pools Lottery Match 4 prize",
    prz3: "100x the Torino Pools Lottery Match 3 prize",
    htp5: "*In exceptional circumstances, Torino Pools Lottery players may receive a smaller prize than advertised which will be calculated based on the prize pool and the number of winners.",
    pr: "PLAY RESPONSIBILITY",
    pr1: "Gambling should never be a problem.",
    pr2: "The Torino Pools Lottery wants everyone to enjoy playing lottery games and wants everyone to play them responsibly. We believe that one problem gambler is one too many. Most people gamble for fun and use money from their entertainment budget to go to a casino or a horse race, to play cards or bingo or to buy a lottery ticket.",
    pr3: "But, according to the City Council on Problem Gambling, a few people, about three percent of adults, experience gambling-related problems. These people cannot safely manage any kind of wagering. They have a disease called problem gambling. Luckily there is help for those who think they or someone they care about might have a problem with gambling.",
    pr4: "A number of private organizations offer assistance and we do as well. Because we're an agency whose mission is to provide public benefits, individuals with gambling problems and their families can find information, counseling and assistance for problem gambling at Torino Pools Lottery Retailers, the Problem Gambling Help Line at: 0808 8020 133. Or you can visit BeGambleAware website for more information.",
  },
};
